
export const loginWithSessionTokenAsync = (idToken: string): void =>
    window.location.replace(`/Oauth/LoginWithAWSIdToken?token=${idToken}`);


export const GetAuthDetails = (email: string, redirectUrl: string): Promise<C2GAuthDetails> =>
    fetch('/Oauth/GetAuthDetails', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            email,
            redirectUrl
        })
    }).then(async res => {
        if (res.ok) {
            return res.json()
        } else throw new Error(res.statusText);
    });

export const GetAWSTokensWithCode = (Code: string, RedirectUrl: string): Promise<TokenResponse> =>
    fetch(`/Oauth/GetAWSTokensWithCode`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            Code,
            RedirectUrl
        })
    }).then(res => {
        if (res.ok) {
            return res.json()
        } else throw new Error(res.statusText);
    });
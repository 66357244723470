import React from 'react';
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import {
    VerifyContact,
    FormSection,
    SectionHeader,
    SectionBody,
    SectionFooter,
    InputRow,
    RadioRow,
    MessageRow,
    ButtonRow,
    Link
} from 'aws-amplify-react';
import {Auth} from "@aws-amplify/auth";

const logger = new Logger('VerifyContact');

interface VerifyContactProps {
    setLoading: (loading: boolean) => void;
}

export default class extends VerifyContact<VerifyContactProps> {
    verify() {
        const { contact, checkedValue } = this.inputs;
        if (!contact) {
            this.error('Neither Email nor Phone Number selected');
            return;
        }

        if (!Auth || typeof Auth.verifyCurrentUserAttribute !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        this.props.setLoading(true);
        Auth.verifyCurrentUserAttribute(checkedValue)
            .then(data => {
                this.props.setLoading(false);
                logger.debug(data);
                this.setState({ verifyAttr: checkedValue });
            })
            .catch(err => {
                this.props.setLoading(false);
                this.error(err);
            });
    }

    submit() {
        const attr: any = this.state.verifyAttr;
        const { code } = this.inputs;
        if (!Auth || typeof Auth.verifyCurrentUserAttributeSubmit !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        this.props.setLoading(true);
        Auth.verifyCurrentUserAttributeSubmit(attr, code)
            .then(data => {
                this.props.setLoading(false);
                logger.debug(data);
                this.changeState('signedIn', this.props.authData);
                this.setState({ verifyAttr: null });
            })
            .catch(err => {
                this.props.setLoading(false);
                this.error(err);
            });
    }

    verifyView() {
        const user = this.props.authData;
        if (!user) {
            logger.debug('no user for verify');
            return null;
        }
        const { unverified } = user;
        if (!unverified) {
            logger.debug('no unverified on user');
            return null;
        }
        const { email, phone_number } = unverified;
        const theme = this.props.theme;
        return (
            <div>
                {email ? <RadioRow
                    placeholder="Email"
                    theme={theme}
                    key="email"
                    name="contact"
                    value="email"
                    onChange={this.handleInputChange}
                /> : null
                }
                {phone_number ? <RadioRow
                    placeholder="Phone Number"
                    theme={theme}
                    key="phone_number"
                    name="contact"
                    value="phone_number"
                    onChange={this.handleInputChange}
                /> : null
                }
                <ButtonRow theme={theme} onClick={this.verify}>Verify</ButtonRow>
            </div>
        )
    }

    submitView() {
        const theme = this.props.theme;
        return (
            <div>
                <InputRow
                    placeholder="Code"
                    theme={theme}
                    key="code"
                    name="code"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                />
                <ButtonRow theme={theme} onClick={this.submit}>Submit</ButtonRow>
            </div>
        )
    }

    showComponent(theme: any) {
        const { authData, hide } = this.props;

        return (
            <FormSection theme={theme}>
                <SectionHeader theme={theme}>Verify Contact</SectionHeader>
                <SectionBody theme={theme}>
                    <MessageRow theme={theme}>
                        Account recovery requires verified contact information
                    </MessageRow>
                    {this.state.verifyAttr ? this.submitView() : this.verifyView()}
                </SectionBody>
                <SectionFooter theme={theme}>
                    <Link theme={theme} onClick={() => this.changeState('signedIn', authData)}>
                        Skip
                    </Link>
                </SectionFooter>
            </FormSection>
        )
    }
}
import React from 'react';

export const Container = {
    fontSize: '14px',
    textAling: 'center'
}

export const ErrorSection = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    backgroundColor: 'rgb(252, 74, 26)',
    border: 'none',
    textAlign: 'center',
    padding: '10px'
}

export const SectionHeader = {
    overflow: 'hidden',
    textAlign: 'center',
    color: '##262626',
    backgroundColor: '#fff',
    padding: '20px 20px',
    borderBottom: '1px solid #e6e6e6'
}

export const SectionFooter = {
    textAlign: 'center',
    color: '##262626',
    backgroundColor: '#fff',
    padding: '20px 20px',
    marginTop: '10px',
    boxSizing: 'border-box',
    borderTop: '1px solid #e6e6e6'
}

export const FormRow = {
    margin: '10px 20px',
    textAlign: 'left'
}


export const ActionRow = {
    margin: '0 20px',
}

export const Input = {
    display: 'block',
    width: '100%',
    background: '#fafafa',
    padding: '9px 0 7px 8px',
    border: '1px solid #efefef',
    borderRadius: '3px',
    lineHeight: '18px',
    boxSizing: 'border-box'
}


export const Button = {
    display: 'inline-block',
    cursor: 'pointer',
    fontWeight: 600,
    lineHeight: '26px',
    padding: '6px 16px',
    marginTop: '10px',
    border: 'none',
    borderRadius: '30px',
    color: '#fff',
    backgroundColor: '#fc4a1a',
    width: '100%',
    outline: 'none',
    '#disabledButton': {
        backgroundColor: '#ffaa93',
    }
}

export const SignInButton = {
    display: 'inline-block',
    cursor: 'pointer',
    fontWeight: 600,
    lineHeight: '26px',
    padding: '6px 16px',
    marginTop: '10px',
    border: 'none',
    borderRadius: '30px',
    color: '#fff',
    backgroundColor: '#fc4a1a',
    width: '100%',
    marginBottom: '10px',
    outline: 'none',
    '#google_signin_btn': {
        backgroundColor: 'rgb(221, 75, 57)',
    },
    '#disabledButton': {
        backgroundColor: '#ffaa93',
    }
}

export const A = {
    color: '#3897f0',
    cursor: 'pointer'
}

export default {
    container: Container,
    errorSection: ErrorSection,
    sectionHeader: SectionHeader,
    sectionFooter: SectionFooter,

    formRow: FormRow,
    actionRow: ActionRow,
    signInButton: SignInButton,

    input: Input,
    button: Button,
    a: A,
}
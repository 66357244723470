import React from 'react';
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import {Auth} from '@aws-amplify/auth';
import {
    InputRow,
    FormSection,
    SectionBody,
    SectionFooter,
    ButtonRow,
    SectionHeader,
    Link,
    ForgotPassword
} from 'aws-amplify-react';
import validator from "validator";
import isEmail = validator.isEmail;


const logger = new Logger('ForgotPassword');

interface ForgotPasswordProps {
    setLoading: (loading: boolean) => void
    username: string
    onChangeUsername: (event: React.ChangeEvent<HTMLInputElement>) => void
};

export default class extends ForgotPassword<ForgotPasswordProps, { delivery: any }> {
    send() {
        const { username } = this.props;
        if (!Auth || typeof Auth.forgotPassword !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        if (username) {
            this.props.setLoading(true);
            Auth.forgotPassword(username)
                .then(data => {
                    this.props.setLoading(false);
                    this.setState({ delivery: data.CodeDeliveryDetails });
                })
                .catch(err => {
                    this.props.setLoading(false);
                    this.setState({ delivery: true });
                });
        }
    }

    submit() {
        const { username } = this.props;
        const { code, password } = this.inputs;
        if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        if (username) {
            this.props.setLoading(true);
            Auth.forgotPasswordSubmit(username, code, password)
                .then(data => {
                    this.props.setLoading(false);
                    this.changeState('signIn');
                    this.setState({ delivery: null });
                })
                .catch(err => {
                    this.props.setLoading(false);
                    this.error(err);
                });
        }
    }

    sendView() {
        const { theme, username, onChangeUsername } = this.props;
        return (
            <div>
                <SectionHeader theme={theme}>A password-reset code will be sent to your email</SectionHeader>
                <InputRow
                    autoFocus
                    placeholder="Email"
                    theme={theme}
                    key="username"
                    name="username"
                    value={username}
                    onChange={onChangeUsername}
                />
                <ButtonRow theme={theme} onClick={this.send}>
                    Send Code
                </ButtonRow>
            </div>
        )
    }

    submitView() {
        const { theme, username } = this.props;
        return (
            <div>
                <SectionHeader theme={theme}>Please enter the code and a new password</SectionHeader>
                <InputRow
                    placeholder="Code"
                    theme={theme}
                    key="code"
                    name="code"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                />
                <InputRow
                    placeholder="New Password"
                    theme={theme}
                    type="password"
                    key="password"
                    name="password"
                    onChange={this.handleInputChange}
                />
                <ButtonRow theme={theme} onClick={this.submit} disabled={!(username && isEmail(username))}>
                    Submit
                </ButtonRow>
            </div>
        )
    }

    showComponent(theme: any) {
        return (
            <FormSection theme={theme}>
                <SectionBody>
                    {this.state.delivery ? this.submitView() : this.sendView()}
                </SectionBody>
                <SectionFooter theme={theme}>
                    <Link theme={theme} onClick={() => {
                        this.setState({ delivery: null });
                        this.changeState('signIn')}
                    }>
                        Back to Sign In
                    </Link>
                </SectionFooter>
            </FormSection>
        )
    }
}
import './helpers/polyfills';

import React from 'react';
import {render} from 'react-dom';

import LoginScreen from './screens/LoginScreen';

const root = document.getElementById('login-box');
const divData = document.getElementById('divData');

if (divData) {
    const { region, userPoolId, clientId } = divData.dataset;
    if (region && userPoolId && clientId) {
        render(
            <LoginScreen
                region={region}
                userPoolId={userPoolId}
                userPoolWebClientId={clientId}
            />,
            root
        );
    }
} 
